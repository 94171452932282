<template>
  <TTView>
    <VRow>
      <VCol>
        <ChoiceCard :choice="choice" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import ChoiceCard from '../../../components/multi-level-test/choices/ChoiceCard.vue';

export default {
  name: 'QuestionsChoicesView',

  components: {
    ChoiceCard,
  },

  data() {
    return {
      loading: false,
      choice: {
        title: null,
        right: null,
        questionId: null,
      },
    };
  },

  computed: {
    testId() {
      return this.$route.params.testId;
    },

    levelId() {
      return this.$route.params.levelId;
    },

    questionId() {
      return this.$route.params.questionId;
    },

    choiceId() {
      return this.$route.params.choiceId;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { choiceId } = this;
        const data = { id: choiceId };
        const { choice } = await this.$di.api.MultiLevelTest.ChoicesGet({ data });

        this.choice = choice;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
